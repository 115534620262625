a {
  text-decoration: none !important;
}

.alert {
  font-size: 14px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}

/* hover whatsapp icon product_detail*/
.u-layout-cell .u-social-whatsapp:hover {
  color: #25d366 !important;
}

.u-container-style:hover .u-icon:hover {
  color: #4d4d4d !important;
}

/* Logo image Header */
.u-header .u-image-1 {
  width: 110px;
  height: 70px;
  margin: 0 auto 0 38px;
}

.sticky-hide {
  display: none !important;
}
.sticky-show {
  display: table !important;
}

.contact-socials {
  list-style-type: none;
}

footer {
  background-color: var(--bs-primary);
  color: #ffffff;
  padding-top: 50px;
}

footer .footer-navigation {
  padding: 0px 10px;
  list-style-type: none;
}
footer ul {
  padding: 0px !important;
  list-style-type: none;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 30px;
}
footer .nav-link {
  color: #ffffff;
  padding: 0px;
}

footer .nav-link:hover {
  color: lightgray;
}
.contact-nav {
  display: flex;
  justify-content: center;
  max-width: 40%;
  margin: 0 auto;
}
footer .contact-nav .flex-grow {
  flex-grow: 1;
}
.footer-logo {
  width: 100%;
  height: 100%;
}

.footer-logo-image {
  max-width: 100%;
  max-height: 100%;
}

.footer-icn {
  font-size: 20px;
  padding: 0px 8px !important;
}

.footer-col {
  text-align: center;
}
.footer-icon-col {
  align-items: center;
}

.footer img {
  object-fit: contain;
}

.footer-logo-image {
  max-height: 120px;
}

.footer-logo {
  justify-content: center;
}

.payment-icon {
  font-size: 20px;
  padding: 0 8px;
}

.footer-icn:hover,
.payment-icon:hover {
  transform: scale(1.2);
}

.footer-divider {
  border-bottom: 1px solid white;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
@media (min-width: 576px) {
  .footer-icon-col {
    align-items: center;
  }
}

@media (min-width: 768px) {
  footer .nav-link {
    max-width: 100%;
  }
  .footer-col {
    text-align: start;
  }
  .footer-icon-col {
    align-items: start;
  }
  .footer-logo {
    justify-content: start;
  }
}

@media (min-width: 992px) {
  .col-right {
    align-items: end;
  }
}

.u-enable-responsive .u-nav-container {
  display: block !important;
}
.u-enable-responsive .u-nav-container .u-nav-item:not(.show) {
  display: none;
}
.u-enable-responsive .u-nav-container .u-nav-item.show {
  margin: 0 18px 0 0 !important;
}
.u-nav-item i {
  color: var(--bs-primary);
}

@media (max-width: 991px) {
  .u-nav-1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.u-offcanvas .u-sidenav {
  flex-basis: 350px !important;
}


.content-toast-wrapper {
  position: fixed;
  z-index: 10;
  bottom: 15px;
  right: 25px;
}
.content-toast-wrapper:hover {
  cursor: pointer;
}
.contact-icon.bi-whatsapp {
  color: #fff;
}

.icn-wrapper {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  background: #25d366;
}
.contact-icon-link, .bi-whatsapp {
  font-size: 30px;
  width: 100%;
  height: 100%;
}
.contact-icon::before {
  width: 50px;
  height: 50px;
  text-align: center;
}
.popover {
  background-color: transparent;
  border: 0;
}
.popover-body {
  padding: 0px;
}
.popper-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 8px;
  margin-bottom: 4px;
  border-radius: 50%;
  background: white;
}
.popper-item .fa {
  font-size: 25px;
  margin: 6px 6px !important;
  width: 25px;
  height: 25px;
  display: flex;
  align-items:center

}
.popper-item:hover {
  background-color: #c4c4c430;
}
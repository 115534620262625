$navbar-breakpoint: $lg;

body {
  min-height: 100vh !important;
  height: auto !important;
}

.header {
  z-index: 100;
}

.header-row {
  z-index: 1000;
  background-color: white;
  display: flex;
  width: 100%;
}
.header-row-navigation {
  z-index: 1001;
  position: sticky;
  display: flex;
  flex-direction: column;
  top: 0;
  border-bottom: 2px solid var(--bs-primary);
}
.navbar-productgroup-wrapper > .navbar-productgroup {
  margin-left: auto;
  margin-right: auto;
}

.navbar-logo {
  height: 110px;
  padding: 5px 0;
}

.navbar {
  display: flex;
  align-items: center;
  padding: 0;
}

.nav-list {
  display: flex;
  align-items: center;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.nav-list-children {
  visibility: hidden;
  background-color: white;
  display: flex;
  align-items: start;
  flex-direction: column;
  top: calc(100% + 2px);
  left: 0;
  position: absolute;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.nav-list-children .nav-list-children {
  border-left: 1px solid var(--bs-primary);
  left: 100%;
  top: 0;
}

.nav-item:hover {
  background-color: var(--bs-light);
  border-bottom: 2px solid var(--bs-primary);
}

.nav-item:hover > .nav-item-link + .nav-list-children {
  visibility: visible;
  z-index: 1;
}

.nav-item-link:has(+ .nav-list .nav-item)::after {
  content: "\25BE";
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
  border-left: 1px solid var(--bs-light);
}
.nav-item-link:has(+ .nav-list .nav-item)::after {
  content: "\25BE";
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
  border-left: 1px solid var(--bs-light);
}

.nav-list-children .nav-item-link:has(+ .nav-list .nav-item)::after {
  content: "\25B8";
}

.nav-item {
  display: flex;
  flex-direction: column;
  position: relative;
  border-bottom: 2px solid transparent;
}
.nav-item .item-link {
  padding: 10px 10px;
}
.mobile-nav-item .item-link{
  text-transform: uppercase;
}

.nav-item .navbar-icon-link {
    padding: 4px 6px;
  }
  
.nav-item-link {
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 100%;
}

.nav-item-icon {
  color: var(--bs-primary);
  display: flex;
  margin-right: 0.5rem;
}

.nav-list-children .nav-item {
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid var(--bs-primary);
}

ul:not(.u-unstyled) {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: auto;
  font-weight: auto;
}

.tp-widget-rating {
  margin: 0 !important;
  font-size: 12px !important;
}

.navbar-home .nav-list {
  display: none;
}
.navbar-home {
  margin-right: auto;
  order:10;
}
.navbar-custom {
  order: 20;
}

.navbar-pages {
  order: 40;
}
.navbar-productgroup {
  order: 30;
}
.navbar-icons {
  order: 50;
}
.navbar-hamburger {
  order: 60;
}

@media only screen and (min-width: $navbar-breakpoint) {
  .navbar-hamburger{
    display: none;
  }

}

@media only screen and (max-width: $navbar-breakpoint) {
  .header-row-navigation {
    padding: 0 10px;
  }
  .navbar-pages {
    display: none;
  }
  .navbar-custom{
    display: none;
  }
  .navbar-productgroup {
    display: none;
  }
  .menu-collapse {
    display: flex !important;
  }
  .navbar-icons {
  }
  .navbar-logo {
    height: 70px;
  }
}

.backdrop {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
}

.mobile-navbar {
  margin-left: auto;
  height: 100vh;
  min-width: 70%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
}
.mobile-sidebar-close {
  display: flex;
  font-size: 25px;
  margin-top: 25px;
}
.navbar-icon-link {
  font-size: 20px;
}
.cart-counter {
    padding: .4rem;
    position: absolute;
    top: 0;
    border: 1px solid var(--bs-border-color);
    right: -5px;
    border-radius: 50%;
}
.cart-counter-text {
  width: 4px;
  height: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bs-dark);
  font-size: 8px;
}
.mobile-menu {
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  // padding: 20px 10px;
  background-color: #ffffffe8;
  z-index: 1001;
}
.mobile-menu.show {
  display: flex;
  flex-direction: row;
}

.mobile-nav-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.mobile-nav-list-children {
  display: none;
  background-color: #f8f9fa45;
  border-right: 1px solid var(--bs-primary);
  border-left: 1px solid var(--bs-primary);
}

.mobile-nav-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  min-width: 60%;
  width: fit-content;
  padding: 10px;
}
.mobile-nav-list-children .mobile-nav-item {
  border-bottom: 0 solid var(--bs-primary);
}
.mobile-nav-link {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-nav-link a {
  text-wrap: balance;
  text-align: center;
}

.mobile-nav-link:has(+ .mobile-nav-list .mobile-nav-item) {
  padding-left: 24px;
}
.mobile-nav-link:has(+ .mobile-nav-list .mobile-nav-item)::after {
  content: "\25BE";
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
  border-left: 1px solid var(--bs-primary);
}

.mobile-nav-link.show-children + .mobile-nav-list {
  display: flex;
}

.header-socials {
  padding: 0px !important;
  display: inline;
  color: white;
  list-style-type: none;
  margin: 0px !important;
}

.u-section-row.u-sticky-fixed {
  z-index: 40 !important;
}

.header-socials li {
  display: inline;
}

.header-socials i {
  color: white;
}

.u-layout {
  padding: 4px 0;
}

.navbar-logo-wrapper {
  display: flex;
  align-items: center;
  flex: 0 0 30%;
  max-width: 30%;
  min-width: 30%;
}

@media (max-width: 575px) {
  .u-layout-row > .u-size-34-xs {
    flex: 0 0 56.66666667%;
    max-width: 56.66666667%;
    min-width: 56.66666667%;
  }
  .u-layout-row > .navbar-logo-wrapper {
    max-width: 43.333333333333%;
    min-width: 43.333333333333%;
  }
  header .u-icon-8 {
    margin: -49px 94px 0 auto !important;
  }
}

.navbar-logo {
  max-height: 100%;
}

.navbar-logo-image {
  max-height: 100%;
  max-width: 100%;
}

@media (max-width: 575px) {
  .u-sheet {
    width: 100% !important;
    padding: 0 10%;
  }
}

@media (max-width: 575px) {
  .u-header .u-group-1 {
    margin-top: -1px;
    margin-right: 43px;
  }
}

@media (max-width: 575px) {
  .u-header .u-icon-8 {
    margin-top: -49px;
    margin-right: auto;
    margin-left: 76px;
  }
}

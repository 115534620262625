.custom-modal {
    position: fixed;
    top: 12.5vh;
    right: -100%;
    width: 75%;
    height: 75vh;
    z-index: 1003;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    transition: right 0.5s ease-in-out;
    overflow-x: hidden;
  }

.custom-modal-close{
    font-size: 24px;
    position: absolute;
    left: 0;
    top: 0;
}
.custom-modal-content {
}
.custom-modal-body{ 
    padding: 24px 48px;
}
.custom-modal-footer{
    margin-top:auto;
    margin-bottom:12px;
}
.custom-modal-title{
    background-color: var(--bs-light);
    text-align: center;
    padding: 25px 0;
}

.custom-modal h2 {
    margin: 0;
}

.custom-modal-backdrop {
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    background-color: #00000054;
    z-index: 1002;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
}

.custom-modal.active {
    right: 12.5%;
    border-radius: 4px;
    position: fixed;
}
.custom-modal-content{
    height: 100%;
    display: flex;
    flex-direction: column;
}

@media (max-width: $md) {
    .custom-modal {
        height: 85vh;
        top: 7.5vh;
    }
    
}

/* Checkout content */

.quantity-col{
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
}
.delete-line{
  width: fit-content;
  position: absolute;
  right: 0;
}
.product-title{
    color: var(--bs-primary) !important;
}

.cart-cell{
  vertical-align: middle;
}
.product-cell{
}
.quantity-cell{
  display: flex;
  align-items: center;
}
.price-cell{
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart-line-quantity{
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.cart-line-quantity > *{
  width: 55px !important;
}
@media (max-width: 768px) {
  .cart-line-quantity > *{
    width: auto;
  }
}


.btn-quantity{
    width:4rem;
}
.price-wrap{
    display: flex;
    flex-direction: column;
}

.price{
    font-family: sans-serif;
}


.btn-light{
    border-radius: 1px;
    background-color: rgb(222 222 222 / 33%) !important;
    color: var(--bs-primary);
}
/* Checkout page */

.col-form-label {
  font-size: 14px !important;
  font-weight: normal;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.form-check{
    display: flex;
    align-items: center;
}

.radio-wrapper{
  overflow: auto;
}

.radio-card{
  margin: 5px 10px;
  height: 90%;
  width:fit-content;
  border: 1px solid var(--bs-border-color);
}

.form-check input[type="radio"]{
    margin: 0rem !important;
    padding:0; 
    min-width: 15px;
}
.form-check .form-check-label, .form-check .form-label{
  margin-bottom: 4px;
}

.checkout-form {
  .delivery-date{
    display: none;
  }
  input:checked ~ .delivery-date {
    display: flex;
  }
}


p:not(.u-text-variant) {
  margin-top: 0;
  margin-bottom: 0;
}
.form-check-input:checked {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
}

.form-label {
    margin-bottom: 0;
}

/* Order Review */ 
.img-sm{
  width: 100px;

}

.form-switch{
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);

}

fieldset {
  border:0;
}

.form-group{
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;

}

.step{
  width: 25%;
  text-align: center;
  border-top: 2px solid var(--bs-border-color);

}
.step.fullfilled:hover{
  background-color: var(--bs-light);
  cursor: pointer;
}
.step.fullfilled{
  border-color: var(--bs-success);
}
.step.fullfilled small{
  border-bottom: 1px solid grey;
}
.step.fullfilled:hover small{
  border-bottom: 1px solid var(--bs-primary);
}

.step.current{
  border-color: var(--bs-warning);
}

@media screen and (max-width: $md) {
  .step:not(.current) small{
    font-size: 0;
  }

}
.form-check-input {
  position: static;
  margin-top: 0;
  margin-left: 0;
}

.form-checkout{
  border: 1px solid lightgrey;
  border-radius: .25rem;
  margin-bottom: 0px;
}

.delivery_date {
  padding: 0.5rem;

}
.delivery_date:not(:first-child){
  border-top: 1px solid lightgrey;

} 

#variant_form input[type="radio"]{
  display: none;
}
#variant_form input[type=checkbox] {
  display: none;
}

.order_confirm_form .form-check-label{
  width: 95%;
}

.delivery_date{
  font-size: 14px;
}

#homeDelivery_false ~ #pick_update{
  display: none;
}

#homeDelivery_true ~ #delivery_date{
  display: none ;
}

#homeDelivery_false:checked ~ #pick_update{
  display: flex;
  flex-direction: column;

}

#homeDelivery_true:checked ~ #delivery_date{
  display: flex;
  flex-direction: column;
}

textarea{
  font-size: 13px !important;
  padding: 8px 8px !important;
}


.form-check{
  display: flex;
  align-items: center;
}
.dateinput{
  margin:0 !important;
  font-size: 12px;
  border-radius: 4px;

}

.discount-input{
  font-size: 12px;
  padding: 1rem 1rem;
  border-radius: 0;
}

.old-price{
  text-decoration: line-through solid #4D4D4D;
  -webkit-text-decoration-line: line-through;
  -webkit-text-decoration-color: red;
  line-height: 1;
  font-size: 14px;
}


.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}
.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}